import IndLogo from "../assest/img/Dubai Property-Expo-logo.png";
import IntroLogo from "../assest/img/kenyalanding/Introductions-logo.png";
import ToiLogo from "../assest/img/kenyalanding/toi.png";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
export default function PrivacyPolicyDubai() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Open modal after 5 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            handleShow();
        }, 2000); // 5 seconds delay

        return () => clearTimeout(timer); // Cleanup timeout on component unmount
    }, []);
    return (
        <>
            <section id="navbar" className="sticky-top shadow-sm">
                <nav className="navbar navbar-expand-md navbar-white bg-white">
                    <div className="container-fluid">
                        <div className=" p-0">
                            <a className="navbar-brand m-0 main-logo" href="./">
                                <img src={IndLogo} alt="India Realty Show Logo" />
                                <p>Hong Kong</p>
                            </a>
                        </div>
                        <div className=" btn-register" onClick={handleShow}>
                            Free Visitor Registration
                        </div>
                        {/* <div className=" p-0">
              <a className="navbar-brand m-0" href="./">
                <img src={ToiLogo} alt="Times of India Logo" />
              </a>
            </div> */}
                    </div>
                </nav>
            </section>
            <section>
                <div className="container-fluid privacy-policy-page my-5">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-center">Privacy Policy</h1>
                            <p>
                                India Property Expo privacy policy explains how we
                                collect, use, and protect your personal information when you
                                attend our property exhibition events.
                            </p>
                            <b>What Information We Collect</b>
                            <p>We collect the following types of personal information:</p>
                            <ul>
                                <li>
                                    <b>Contact Information:</b> Your name, email address, phone
                                    number, and address.
                                </li>
                                <li>
                                    <b>Registration Information:</b> Information you provide when
                                    signing up for an event.
                                </li>
                                <li>
                                    <b>Feedback and Communication:</b> Your comments, questions,
                                    and other correspondence with us.
                                </li>
                            </ul>
                            <b>How We Use Your Information</b>
                            <p>We use your information to:</p>
                            <ul>
                                <li>Organize and manage your participation in our events.</li>
                                <li>Provide you with updates and event invitations.</li>
                                <li>Improve our services based on your feedback.</li>
                                <li>Respond to your inquiries.</li>
                            </ul>
                            <b>Sharing Your Information</b>
                            <p>We may share your information with:</p>
                            <ul>
                                <li>
                                    <b>Event Partners:</b> To give you information about
                                    properties and services.
                                </li>
                                <li>
                                    <b>Service Providers:</b> Who help us manage our events and
                                    services.
                                </li>
                                <li>
                                    <b>Authorities:</b> If required by law.
                                </li>
                            </ul>
                            <b>Data Retention</b>
                            <p>
                                We keep your information as long as needed for our services and
                                to meet legal requirements.
                            </p>
                            <b>Security</b>
                            <p>
                                We take steps to protect your information from unauthorized
                                access or misuse.
                            </p>
                            <b>Changes to This Privacy Policy</b>
                            <p>
                                We may update this policy. Any changes will be posted on our
                                website.
                            </p>
                            <b>Contact Us</b>
                            <p>
                                If you have questions about this privacy policy, please contact
                                us at:
                            </p>
                            <ul>
                                <li>
                                    Email:{" "}
                                    <a
                                        href="mailto:sales.infairs@gmail.com"
                                        className="text-decoration-none text-dark">
                                        sales.infairs@gmail.com
                                    </a>
                                </li>
                                <li>
                                    Phone:{" "}
                                    <a
                                        href="tel:+917880038782"
                                        className="text-decoration-none text-dark">
                                        +91 78800 38782
                                    </a>
                                </li>
                                <li>
                                    Address: <strong>REGAL KOWLOON HOTEL</strong>
                                    <br />
                                    Montparnasse Room - 2nd floor,
                                    <br /> 71 Mody Road, Tsimshatsui, Hong Kong
                                </li>
                            </ul>
                            <p>
                                Thank you for being part of India Property Expo. We value
                                your privacy and strive to protect your information.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="bg-light py-4">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-lg-2 mb-4 mb-lg-0">
                            <img
                                src={IndLogo}
                                className="img-fluid"
                                alt="India Realty Show Logo"
                            />
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4 mb-lg-0 ps-lg-4">
                            <p className="mb-0">
                                <strong>REGAL KOWLOON HOTEL</strong>
                                <br />
                                Montparnasse Room - 2nd floor,<br /> 71 Mody Road, Tsimshatsui, Hong
                                Kong
                            </p>

                            <a
                                href="https://maps.app.goo.gl/TUCHZo6E85aHc8HC9"
                                target="_blank"
                                rel="noopener noreferrer">
                                <i className="fa-solid fa-location-dot"></i> Click here for
                                direction
                            </a>
                        </div>
                        <div className="col-md-6 col-lg-3 ps-lg-5">
                            <ul className="footer-contact-links">
                                <li>
                                    <a href="tel:+917880038782">
                                        <i className="fa-solid fa-phone"></i> +91 78800 38782
                                    </a>
                                </li>
                                <li>
                                    <a href="https://wa.me/+917880038782">
                                        <i className="fa-brands fa-whatsapp"></i> +91 78800 38782
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:sales.infairs@gmail.com">
                                        <i className="fa-solid fa-envelope"></i>
                                        <span>sales.infairs@gmail.com</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-2">
                            <b>Follow Us On</b>
                            <ul className="footer-contact-links mt-2">
                                <li>
                                    <a
                                        href="https://www.facebook.com/nriniveshpropertyexpo/"
                                        target="_blank">
                                        <i className="fa-brands fa-facebook"></i> Facebook
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.instagram.com/indiapropertyexpointroductions/"
                                        target="_blank">
                                        <i className="fa-brands fa-instagram"></i> Instagram
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-2 mb-4 mb-lg-0">
                            <b className="d-block">Organized By</b>
                            <img
                                src={IntroLogo}
                                className="img-fluid mt-2"
                                alt="Introduction Trade Shows Logo"
                            />
                        </div>
                        <div className="col-lg-8 mx-auto text-center">
                            <p className="footer-text">
                                This site is not a part of the Facebook
                                website or Facebook Inc . Additionally this site is not endorsed
                                by Facebook or FACEBOOK, Inc. in any way.
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="container-fluid px-lg-4 bg-dark py-2">
                <div className="row">
                    <div className="col-md-6 text-center text-lg-start text-white">
                        <p className="cursive-font mb-0 pt-1">
                            Come visit and buy your dream property
                        </p>
                    </div>
                    <div className="col-md-6 text-white text-center text-lg-end">
                        <a
                            href="/privacy-policy.html"
                            className="w-auto mt-2 d-inline-block text-white text-decoration-none">
                            Privacy Policy
                        </a>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton className="model-cross-visitor">
                    <Modal.Title>Free Visitor Registration</Modal.Title>
                </Modal.Header>
                <Modal.Body className="model-cross-visitor p-0">
                    <iframe
                        src="https://indiapropexpo.com/visitorregistrationdubai"
                        className="iframe-model"
                        frameborder="0"></iframe>
                </Modal.Body>
            </Modal>
        </>
    );
}
