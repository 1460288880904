import "./DubaiLanding.css";
import IndLogo from "../assest/img/Dubai Property-Expo-logo.png";
import IntroLogo from "../assest/img/kenyalanding/Introductions-logo.png";
import ToiLogo from "../assest/img/kenyalanding/toi.png";
import HongKongBanner from "../assest/img/kenyalanding/banner/hong-kong-banner.png";
import Pune from "../assest/img/kenyalanding/cities/pune.png";
import Mumbai from "../assest/img/kenyalanding/cities/mumbai.png";
import Gujarat from "../assest/img/kenyalanding/cities/Gujarat.png";
import DelhiNCR from "../assest/img/kenyalanding/cities/delhi-ncr.png";
import Gurugram from "../assest/img/kenyalanding/cities/gurugram.png";
import Hyderabad from "../assest/img/kenyalanding/cities/Hyderabad.png";
import Chennai from "../assest/img/kenyalanding/cities/chennai.png";
import Bangalore from "../assest/img/kenyalanding/cities/bangalore.png";
import Dubai from "../assest/img/kenyalanding/cities/Dubai.png";
import Goa from "../assest/img/kenyalanding/cities/Goa.png";
import Noida from "../assest/img/kenyalanding/cities/Noida.png";
import HotIcon from "../assest/img/kenyalanding/hot.png";
import DealIcon from "../assest/img/kenyalanding/deal.png";
import GiftIcon from "../assest/img/kenyalanding/gift.png";
import Residentialimg from "../assest/img/kenyalanding/residentialimg.png";
import Plot from "../assest/img/kenyalanding/plots.png";
import Villa from "../assest/img/kenyalanding/villa.jpg";
import Commercial from "../assest/img/kenyalanding/commercial.png";
import Developers from "../assest/img/kenyalanding/developers.png";
import Projects from "../assest/img/kenyalanding/projects.png";
import Cities from "../assest/img/kenyalanding/15-cities.png";
import Gall1 from "../assest/img/Dubailanding/imageone.png";
import Gall2 from "../assest/img/Dubailanding/imagetwo.png";
import Gall3 from "../assest/img/Dubailanding/imagethree.png";
import Gall4 from "../assest/img/Dubailanding/imagefour.png";
import Gall5 from "../assest/img/Dubailanding/imagefive.png";
import Appreciation from "../assest/img/dubai-images/Capital-appreciation.jpg"
import Economy from "../assest/img/dubai-images/economy.png"
import GoldenVisa from "../assest/img/dubai-images/golden-visa.png"
import Yield from "../assest/img/dubai-images/High-rental-yield.png"
import Investment from "../assest/img/dubai-images/investment.png"
import Taxfree from "../assest/img/dubai-images/tax-free.png"
import Zerotax from "../assest/img/dubai-images/zero-tax.png"
import Favicon from "../assest/img/dubai-images/favicon-dubai.ico"






import { Helmet } from "react-helmet";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import VisitorRegistrationFormIPE from "../Components/IPEForm/VisitorRegistrationFormIPE";
import VisitorRegistrationFormDubai from "../Components/DubaiForm/DubaiForm";
export default function DubaiLanding() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Open modal after 5 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            handleShow();
        }, 2000); // 5 seconds delay

        return () => clearTimeout(timer); // Cleanup timeout on component unmount
    }, []);
    useEffect(() => {
        const scrollBox = document.querySelector(".seven-cards-box");

        if (window.innerWidth <= 568) {
            let scrollAmount = 0;

            const scrollInterval = setInterval(() => {
                if (scrollBox) {
                    scrollBox.scrollBy({ left: 100, behavior: "smooth" });
                    scrollAmount += 100;

                    if (
                        scrollBox.scrollLeft + scrollBox.offsetWidth >=
                        scrollBox.scrollWidth
                    ) {
                        scrollAmount = 0;
                        scrollBox.scrollTo({ left: 0, behavior: "smooth" });
                    }
                }
            }, 2000);

            // Cleanup interval on unmount
            return () => clearInterval(scrollInterval);
        }
    }, []); // Run once on component mount

    return (
        <>
            <Helmet>
                <title>
                    Dubai Property Expo 2025 - Exclusive Dubai Property Expo in Hong Kong
                </title>

                <meta
                    name="title"
                    content="Dubai Property Expo 2025 - Exclusive Dubai Property Expo in Hong Kong"
                />
                <meta
                    name="description"
                    content="Discover premier property deals from Pune, Mumbai, Gujrat and many more in Hong Kong at the."
                />
                <meta
                    name="keyword"
                    content="Property Expo, Dubai Property Expo, Property Exibition, International Property Expo, Times Expo, Realty show, India Realty Show, Dubai Property Expo, Property Developers"
                />
                <meta
                    property="og:title"
                    content="Dubai Property Expo 2025 - Exclusive Dubai Property Expo in Hong Kong"
                />
                <meta
                    property="og:description"
                    content="Discover premier property deals from Pune, Mumbai, Gujrat and many more in Hong Kong at the."
                />
                <meta property="og:site_name" content="India Realty Show" />
                <meta property="og:url" content="https://indiapropexpo.com/dubai" />
                <meta
                    property="og:image"
                    content="https://indiapropexpo.com/static/media/indiapropexpologo.bb5ef163d51e392164ce.png"
                />

                <link rel="canonical" href="https://indiapropexpo.com/dubai" />

                <meta
                    property="twitter:title"
                    content="Dubai Property Expo 2025 - Exclusive Dubai Property Expo in Hong Kong"
                />
                <meta
                    property="twitter:description"
                    content="Discover premier property deals from Bangalore, Chennai, Pune, Vadodara, Mumbai, Delhi, Gurugram and many more in Hong Kong at the."
                />
                <meta
                    name="twitter:image"
                    content="https://indiapropexpo.com/static/media/indiapropexpologo.bb5ef163d51e392164ce.png"
                />
                <link rel="shortcut icon" href={Favicon} type="image/x-icon"></link>
            </Helmet>
            <div className="kenya-landing-page">
                <section id="navbar" className="sticky-top shadow-sm">
                    <nav className="navbar navbar-expand-md navbar-white bg-white">
                        <div className="container-fluid">
                            <div className=" p-0">
                                <a className="navbar-brand m-0 main-logo" href="/dubai">
                                    <img src={IndLogo} alt="Dubai Property Expo Logo" />
                                    <p>Hong Kong</p>
                                </a>
                            </div>
                            <div className=" btn-register" onClick={handleShow}>
                                Free Visitor Registration
                            </div>

                        </div>
                    </nav>
                </section>
                <section>
                    <div className="container-fluid ">
                        <div className="row banner">
                            <img
                                src={HongKongBanner}
                                className="mobile-banner img-fluid"
                                alt="Hong Kong"
                            />
                            <div className="banner-content">
                                <img src={IndLogo} alt="India Realty Show Logo" />

                                <p>
                                    <strong>REGAL KOWLOON HOTEL</strong>
                                    <br />
                                    Montparnasse Room - 2nd floor,<br /> 71 Mody Road, Tsimshatsui, Hong
                                    Kong
                                </p>
                                <a
                                    href="https://maps.app.goo.gl/TUCHZo6E85aHc8HC9"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <i className="fa-solid fa-location-dot"></i> Click here for
                                    direction
                                </a>
                                <p className="mb-0 event-time">
                                    <b>
                                        22<sup>nd</sup> & 23<sup>rd</sup> March 2025
                                    </b>
                                </p>
                                <p className="mb-0 mt-2">
                                    {" "}
                                    <b>Time: 11:00 AM to 8 PM (Sat-Sun)</b>
                                </p>
                                <p className="mb-0">
                                    {" "}
                                    <b>Free Entry</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="form-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 px-lg-0">
                                <VisitorRegistrationFormDubai />
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container py-4">
                        <div className="row align-items-center">
                            <div className="col-12 mb-4">
                                <div className="section-two-right">
                                    <h2 className="heading-style">
                                        Dubai Property Expo - Hong Kong
                                    </h2>
                                    <h2 className="aos-init aos-animate mt-4">
                                        2 Days of Incredible Deals
                                    </h2>
                                    <div className="project-contents-msp pb-2 aos-init aos-animate mb-3">
                                        on properties from all across Dubai
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 mb-4 mb-lg-3 aos-init aos-animate">
                                            <div className="card">
                                                <a className="popup-with-form" onClick={handleShow}>
                                                    <div className="single-features">
                                                        <img src={Yield} alt="residential" />
                                                        <div className="project-title-msp">High Rental Yield</div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 mb-4 mb-lg-3 aos-init aos-animate">
                                            <div className="card">
                                                <a className="popup-with-form" onClick={handleShow}>
                                                    <div className="single-features">
                                                        <img src={Appreciation} alt="residential" />
                                                        <div className="project-title-msp">High Capital Appreciation</div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 mb-4 mb-lg-3 aos-init aos-animate">
                                            <div className="card">
                                                <a className="popup-with-form" onClick={handleShow}>
                                                    <div className="single-features">
                                                        <img src={Taxfree} alt="residential" />
                                                        <div className="project-title-msp">100% Tax Free Income</div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 mb-4 mb-lg-3 aos-init aos-animate">
                                            <div className="card">
                                                <a className="popup-with-form" onClick={handleShow}>
                                                    <div className="single-features">
                                                        <img src={Zerotax} alt="residential" />
                                                        <div className="project-title-msp">Zero Capital Gains Tax</div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 mb-4 mb-lg-3 aos-init aos-animate">
                                            <div className="card">
                                                <a className="popup-with-form" onClick={handleShow}>
                                                    <div className="single-features">
                                                        <img src={GoldenVisa} alt="residential" />
                                                        <div className="project-title-msp">Golden Visa</div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 mb-4 mb-lg-3 aos-init aos-animate">
                                            <div className="card">
                                                <a className="popup-with-form" onClick={handleShow}>
                                                    <div className="single-features">
                                                        <img src={Economy} alt="residential" />
                                                        <div className="project-title-msp">Stable & Growing Economy</div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 mb-4 mb-lg-3 aos-init aos-animate">
                                            <div className="card">
                                                <a className="popup-with-form" onClick={handleShow}>
                                                    <div className="single-features">
                                                        <img src={Investment} alt="residential" />
                                                        <div className="project-title-msp">Ease of Investment</div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/*  */}
                                    {/* <div className="row mb-3">
                                        <div className="dubai-background ">
                                            <div className="dubai-content">
                                                <div className="d-flex gap-5 ">
                                                    <h2 className="march ms-5">
                                                        22nd & 23rd <br /> March
                                                    </h2>
                                                    <div className="dubai-content-regal ms-5">
                                                        <h2>
                                                            REGAL KOWLOON HOTEL
                                                        </h2>
                                                        <h3>71 Mody Road, Trimshatsui, Hong Kong</h3>
                                                        <h4>(Montparnasse Room - 2nd Flr) 11am to 8pm | Sat & Sun | Free Entry.</h4>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    </div> */}
                                    {/* <div class="dubai-landing mb-3">
                                        <div class="content-left-dubai">
                                            <div>22nd & 23rd</div>
                                            <div>March</div>
                                        </div>
                                        <div class="content-right-dubai">
                                            <h2>REGAL KOWLOON HOTEL</h2>
                                            <p>71 Mody Road, Tsimshatsui, Hong Kong</p>
                                            <p class="details">(Montparnasse Room - 2nd Flr) 11 am to 8 pm <span class="highlight">|</span> Sat & Sun <span class="highlight">|</span> Free Entry</p>
                                        </div>
                                    </div> */}
                                    {/*  */}
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 aos-init aos-animate">
                                            <div className="single-services">
                                                <div className="icon">
                                                    <img
                                                        src={HotIcon}
                                                        className="w-100"
                                                        alt="Unbeatable Deals"
                                                    />
                                                </div>
                                                <h3>Unbeatable Deals</h3>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 aos-init aos-animate">
                                            <div className="single-services">
                                                <div className="icon">
                                                    <img
                                                        src={DealIcon}
                                                        className="w-100"
                                                        alt="Flexible Payment Plans"
                                                    />
                                                </div>
                                                <h3>Flexible Payment Plans</h3>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 aos-init aos-animate">
                                            <div className="single-services mb-0">
                                                <div className="icon">
                                                    <img
                                                        src={GiftIcon}
                                                        className="w-100"
                                                        alt="Incredible Pre-Launch Offers"
                                                    />
                                                </div>
                                                <h3>Incredible Pre-Launch Offers</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-12 mb-3 mb-lg-0">
                                <div className="seven-cards-box">
                                    <div className="seven-card">
                                        <img src={Bangalore} className="img-fluid" alt="Chennai" />
                                    </div>
                                    <div className="seven-card">
                                        <img src={Dubai} className="img-fluid" alt="Chennai" />
                                    </div>
                                    <div className="seven-card">
                                        <img src={Mumbai} className="img-fluid" alt="Mumbai" />
                                    </div>
                                    <div className="seven-card">
                                        <img src={Pune} className="img-fluid" alt="Pune" />
                                    </div>
                                    <div className="seven-card">
                                        <img src={Goa} className="img-fluid" alt="Goa" />
                                    </div>
                                    <div className="seven-card">
                                        <img src={Noida} className="img-fluid" alt="Noida" />
                                    </div>
                                    <div className="seven-card">
                                        <img src={DelhiNCR} className="img-fluid" alt="Delhi NCR" />
                                    </div>
                                    <div className="seven-card">
                                        <img src={Gurugram} className="img-fluid" alt="Gurugram" />
                                    </div>
                                    <div className="seven-card">
                                        <img
                                            src={Hyderabad}
                                            className="img-fluid"
                                            alt="Hyderabad"
                                        />
                                    </div>
                                    <div className="seven-card">
                                        <img src={Chennai} className="img-fluid" alt="Chennai" />
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
                <div className="container-fluid bg-event bg-g mb-4">
                    <div className="row position-relative">
                        <div className="col-12">
                            <div className="hero-content">
                                <h2 className="aos-init">Free Entry</h2>
                                <p style={{ fontSize: "30px" }} className="aos-init">
                                    Snacks & High Tea will be provided to all Registered Clients
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid bg-g mt-4">
                    <div className="row align-items-center position-relative">
                        <div className="col-lg-12 col-md-12">
                            <div className="hero-content">
                                <h1 className="aos-init aos-animate">
                                    "Exclusive Dubai Property Expo in Hong-Kong"
                                </h1>
                                <p
                                    style={{ color: "#ffcc00", fontSize: "30px" }}
                                    className="aos-init aos-animate">
                                    Meet Top Property Developers from across Dubai for a 1 on 1
                                    directly right here in Hong Kong.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <section>
                    <div className="container py-4">
                        <div className="row">
                            <div className="col-lg-4 mb-3 mb-lg-0">
                                <div className="developers-card">
                                    <img
                                        className="w-100"
                                        src={Developers}
                                        alt="20+ Developers"
                                    />
                                    <div className="card-constant  w-100">
                                        <h1 className="text-center">15+</h1>
                                        <p className="text-center">Developers</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 mb-3 mb-lg-0">
                                <div className="developers-card">
                                    <img
                                        className="w-100"
                                        src={Projects}
                                        alt="100 + Projects with Exclusive Deals"
                                    />
                                    <div className="card-constant  w-100">
                                        <h1 className="text-center">50+</h1>
                                        <p className="text-center">Projects with Exclusive Deals</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-3 mb-lg-0">
                                <div className="developers-card">
                                    <img className="w-100" src={Cities} alt="15 + Cities" />
                                    <div className="card-constant  w-100">
                                        <h1 className="text-center">15+</h1>
                                        <p className="text-center">Cities</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section className="glimpses-area mb-4 pb-3 py-4">
                    <div className="section-title">
                        <h2 className="aos-init aos-animate">
                            Glimpses of Previous Property Expos
                        </h2>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-4 d-lg-block d-none">
                                <img
                                    src={Gall1}
                                    className="w-100"
                                    alt="Glimpses of Previous Property Expos"
                                />
                            </div>
                            <div className="col-8 d-lg-block d-none">
                                <div className="row">
                                    <div className="col-4">
                                        <img
                                            className="w-100"
                                            src={Gall2}
                                            alt="Glimpses of Previous Property Expos"
                                        />
                                    </div>

                                    <div className="col-4">
                                        <img
                                            src={Gall3}
                                            className="w-100"
                                            alt="Glimpses of Previous Property Expos"
                                        />
                                    </div>
                                    <div className="col-4">
                                        <img
                                            src={Gall4}
                                            className="w-100"
                                            alt="Glimpses of Previous Property Expos"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 mt-3 d-flex">
                                    <img
                                        className="w-100"
                                        src={Gall5}
                                        alt="Glimpses of Previous Property Expos"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row d-lg-none d-block">
                            <div className="col-md-4 mb-3">
                                <img
                                    className="w-100"
                                    src={Gall1}
                                    alt="Glimpses of Previous Property Expos"
                                />
                            </div>
                            <div className="col-md-4 mb-3">
                                <img
                                    className="w-100"
                                    src={Gall2}
                                    alt="Glimpses of Previous Property Expos"
                                />
                            </div>
                            <div className="col-md-4 mb-3">
                                <img
                                    className="w-100"
                                    src={Gall3}
                                    alt="Glimpses of Previous Property Expos"
                                />
                            </div>
                            <div className="col-md-4 mb-3">
                                <img
                                    className="w-100"
                                    src={Gall4}
                                    alt="Glimpses of Previous Property Expos"
                                />
                            </div>
                            <div className="col-md-4 mb-3">
                                <img
                                    className="w-100"
                                    src={Gall5}
                                    alt="Glimpses of Previous Property Expos"
                                />
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className="py-4">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h2 data-aos="zoom-in" className="aos-init aos-animate">
                  Exhibitors
                </h2>
              </div>
            </div>
            <div className="col-12">
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 50,
                  },
                }}
                modules={[Pagination]}
                className="mySwiper">
                <SwiperSlide></SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section> */}

                <footer className="bg-light py-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6 col-lg-2 mb-4 mb-lg-0">
                                <a href="/dubai">
                                    <img
                                        src={IndLogo}
                                        className="img-fluid"
                                        alt="Dubai Property Expo Logo"
                                    />
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-3 mb-4 mb-lg-0 ps-lg-4">
                                <p className="mb-0">
                                    <strong>REGAL KOWLOON HOTEL</strong>
                                    <br />
                                    Montparnasse Room - 2nd floor, <br /> 71 Mody Road, Tsimshatsui, Hong
                                    Kong.
                                </p>

                                <a
                                    href="https://maps.app.goo.gl/TUCHZo6E85aHc8HC9"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <i className="fa-solid fa-location-dot"></i> Click here for
                                    direction
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-3 ps-lg-5">
                                <ul className="footer-contact-links">
                                    <li>
                                        <a href="tel:+917880038782">
                                            <i className="fa-solid fa-phone"></i> +91 78800 38782
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://wa.me/+917880038782">
                                            <i className="fa-brands fa-whatsapp"></i> +91 78800 38782
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:sales.infairs@gmail.com">
                                            <i className="fa-solid fa-envelope"></i>
                                            <span>sales.infairs@gmail.com</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-6 col-lg-2">
                                <b>Follow Us On</b>
                                <ul className="footer-contact-links mt-2">
                                    <li>
                                        <a
                                            href="https://www.facebook.com/nriniveshpropertyexpo/"
                                            target="_blank">
                                            <i className="fa-brands fa-facebook"></i> Facebook
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.instagram.com/indiapropertyexpointroductions/"
                                            target="_blank">
                                            <i className="fa-brands fa-instagram"></i> Instagram
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-6 col-lg-2 mb-4 mb-lg-0">
                                <b className="d-block">Organized By</b>
                                <img
                                    src={IntroLogo}
                                    className="img-fluid mt-2"
                                    alt="Introduction Trade Shows Logo"
                                />
                            </div>
                            <div className="col-lg-8 mx-auto text-center">
                                <p className="footer-text">
                                    This site is not a part of the
                                    Facebook website or Facebook Inc . Additionally this site is
                                    not endorsed by Facebook or FACEBOOK, Inc. in any way.
                                </p>
                            </div>
                        </div>
                    </div>
                </footer>
                <div className="container-fluid px-lg-4 bg-dark py-2">
                    <div className="row">
                        <div className="col-md-6 text-center text-lg-start text-white">
                            <p className="cursive-font mb-0 pt-1">
                                Come visit and buy your dream property
                            </p>
                        </div>
                        <div className="col-md-6 text-white text-center text-lg-end">
                            <a
                                href="/privacy-policy-dubai"
                                className="w-auto mt-2 d-inline-block text-white text-decoration-none">
                                Privacy Policy
                            </a>
                        </div>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton className="model-cross-visitor">
                        <Modal.Title>Free Visitor Registration</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="model-cross-visitor ipe-form-modal p-0">
                        <VisitorRegistrationFormDubai />
                    </Modal.Body>
                </Modal>
                <div className="fixed-bottom d-block bg-white p-2 d-lg-none">
                    <div className=" btn-register w-100" onClick={handleShow}>
                        Free Visitor Registration
                    </div>
                </div>
            </div>
        </>
    );
}
