import "./ThankyouPage.css";
import IndLogo from "../../assest/img/kenyalanding/indiapropexpologo.png";
export default function ThankyouPage() {
  return (
    <>
      <section class="thank-you-wrapper">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="thank-you-page-logo">
                <a href="/">
                  <img src={IndLogo} class="img-fluid logo-img" alt="Logo" />
                </a>
              </div>
              <div class="thank-you-page-content">
                <h1>Thank You!</h1>
                <p>
                  We're grateful for your interest. Feel free to reach out if
                  you have any questions or need assistance.
                </p>
                <a href="/" class="btn-register">
                  {" "}
                  Go back to Homepage{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
