import { useParams } from "react-router-dom";
import PostApiCall from "../AdminPannel/Api/PostApi";
import moment from "moment";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import "./Visitorregistration.css";
import { Select } from "antd";

export default function VisitorRegistrationForm() {
  const [mailtype, setType] = useState("");
  const [clientid, setClientId] = useState("");
  const mailtypefetchurl = useParams();
  const { Option } = Select;
  const [visitorId, setVisitorId] = useState(null);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [residential, setResidential] = useState(false);
  const [propertyCity, setPropertyCity] = useState("");

  const [eventName, setEventName] = useState("");

  const [villa, setVilla] = useState(false);
  const [plots, setPlots] = useState(false);
  const [commercial, setCommercial] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const numberRegex =
    /^\+?(\d{1,3})?[\s\-]*\(?\d{1,4}\)?[\s\-]*\d{1,4}[\s\-]*\d{1,4}$/;

  const [VisitingDate, setVisitingDate] = useState("");
  const [VisitingDateValue, setVisitingDateValue] = useState([
    { Key: 1, Value: "01-06-2024" },
    { Key: 2, Value: "02-06-2024" },
  ]);
  // **** Number of person visiting ****
  const [VisitingPerson, setVisitingPerson] = useState("");
  const [VisitingPersonValue, setVisitingPersonValue] = useState([
    { Key: 1, Value: "One" },
    { Key: 2, Value: "Two" },
    { Key: 3, Value: "Three" },
    { Key: 4, Value: "Four" },
    { Key: 5, Value: "Five" },
    { Key: 6, Value: "Six" },
    { Key: 7, Value: "Seven" },
    { Key: 8, Value: "Eight" },
    { Key: 9, Value: "Nine" },
    { Key: 10, Value: "Ten" },
  ]);

  const isEmailValid = (email) => {
    return emailRegex.test(email);
  };

  const isPhoneValid = (phone) => {
    return numberRegex.test(phone);
  };

  const handleValidateForm = () => {
    if (name == "") {
      Notiflix.Notify.failure("Name is required. Please enter your name.");
      return;
    }
    if (mobile == "") {
      Notiflix.Notify.failure(
        "Mobile number is required. Please enter your mobile number."
      );
      return;
    } else if (!isPhoneValid(mobile)) {
      Notiflix.Notify.failure("Invalid Mobile Number");
      return;
    }
    if (email == "") {
      Notiflix.Notify.failure("Email is required. Please enter your Email.");
      return;
    } else if (!isEmailValid(email)) {
      Notiflix.Notify.failure("Invalid email. Please enter a valid email.");
      return;
    } if (propertyCity == "") {
      Notiflix.Notify.failure('Please select city.');
      return;
    }

    handleSubmitForm();
  };

  const handleSubmitForm = () => {
    Notiflix.Loading.dots("Please Wait...");
    PostApiCall.postRequest(
      {
        // visitorid: visitorId,
        // mailtype: mailtype,
        // mail: "html",
        // clientid: clientid,
        visitorname: name,
        email: email,
        mobile: mobile,
        selectedCity: propertyCity,
        // message: msg,
        // residentials: residential,
        // plots: plots,
        // villa: villa,
        // commercial: commercial,
        // DayofAttending: VisitingDate,
        // person: VisitingPerson,
        // eventname: "Abu Dhabi",
        website: "https://timesrealtyexpo.com/",
      },
      "UpdateVisitorRegistrationTOI"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Form Submitted Succesfully");
          window.parent.location.href =
            "https://timesrealtyexpo.com/thankyou.html";
          setName("");
          setEmail("");
          setMobile("");
          // setTimeout(() => {
          //   window.location.reload();
          // }, 3000);
        }
      })
    );
  };

  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      //   useFontAwesome: true,
      fontSize: "15px",
      timeout: 4000,
    });
  }, []);
  useEffect(() => {
    setType(mailtypefetchurl.mailtype);
    setClientId(mailtypefetchurl.clientid);
  }, []);

  return (
    <>
      <div className="p-3 w-100 visitorformbg">
        <div className="main-heading main-heading-left">
          <h2>Kindly Register Below - Free Visitor Registration!</h2>
          <p>
            <strong>Free Entry-</strong> Snacks & High Tea provided to all
            Registered Clients
          </p>
        </div>
        <div className="row">
          <div className="col-12 col-sm-3 mb-2">
            <label>Name *</label>
            <input
              type="text"
              className="form-control visitor-input py-3"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="col-12 col-sm-3 mb-2">
            <label>Mobile Number *</label>
            <input
              type="text"
              className="form-control visitor-input"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>
          <div className="col-12 col-sm-3 mb-2">
            <label>Email *</label>
            <input
              type="email"
              className="form-control visitor-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="col-12 col-sm-3 mb-2">
            <label>Select a Location *</label>
            <Select
              showSearch
              className="visitor-input form-control"
              placeholder="Select a location"
              optionFilterProp="children"
              onChange={(value) => setPropertyCity(value)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="Mumbai">Mumbai</Option>
              <Option value="Goa">Goa</Option>
              <Option value="Bangalore">Bangalore</Option>
              <Option value="Pune">Pune</Option>
              <Option value="Chennai">Chennai</Option>
              <Option value="Hyderabad">Hyderabad</Option>
              <Option value="Cochin">Cochin</Option>
              <Option value="Vadodara">Vadodara</Option>
              <Option value="Ahmedabad">Ahmedabad</Option>
              <Option value="Delhi NCR">Delhi NCR</Option>
              <Option value="Gurugram">Gurugram</Option>
              <Option value="Kolkata">Kolkata</Option>
              <Option value="Mangalore">Mangalore</Option>
              <Option value="UK">UK</Option>
              <Option value="Dubai">Dubai</Option>
            </Select>
          </div>

          {/* ******* Day of visiting ******* */}
          {/* <div className="col-12 col-sm-6 mb-2">
            <select
              name="consulting"
              className="select visitor-input reason-option minimal bg-white form-control inputselect inputborderradiusbangkok"
              style={{
                borderRadius: "0px !important",
              }}
              value={VisitingDate}
              onChange={(e) => {
                setVisitingDate(e.target.value);
              }}
            >
              <option key="" value="" disabled>
                Day of Attending *
              </option>
              {VisitingDateValue.map((data, index) => {
                return (
                  <option key={data.Key} value={data.Value}>
                    {data.Value}
                  </option>
                );
              })}
            </select>
          </div> */}

          {/* ***** Number of person visiting ***** */}
          {/* <div className="col-12 col-sm-6 mb-2">
            <select
              name="consulting"
              className="select visitor-input reason-option minimal bg-white form-control inputselect inputborderradiusbangkok"
              style={{
                borderRadius: "0px !important",
              }}
              value={VisitingPerson}
              onChange={(e) => {
                setVisitingPerson(e.target.value);
              }}
            >
              <option key="" value="" disabled>
                Number of Persons Visiting *
              </option>
              {VisitingPersonValue.map((data, index) => {
                return (
                  <option key={data.Key} value={data.Value}>
                    {data.Value}
                  </option>
                );
              })}
            </select>
          </div> */}

          {/* <div className="col-12 mb-2">
            <textarea
              className="form-control visitor-text-area"
              placeholder="Kindly mention cities that you are interested in - Bangalore, Chennai, Mumbai, Pune, Delhi NCR, Kochi, Ahmedabad, Vadodara, Hyderabad, Mangalore ,Thrissur, Madurai and many more. *"
              rows={4}
              value={msg}
              onChange={(e) => setMsg(e.target.value)}
            />
          </div> */}

          {/* <div className="col-12 mb-2 mb-lg-0">
            <lable className="visitor-resident-value">Looking for :</lable>
            <div className="d-flex gap-3 mt-2 mb-2 mb-lg-0">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={residential}
                  onClick={() => setResidential(!residential)}
                  checked={residential == true}
                  id="flexCheckDefault"
                />
                <label
                  className="form-check-label visitor-resident-value"
                  for="flexCheckDefault"
                >
                  Residential
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={plots}
                  checked={plots == true}
                  onClick={() => setPlots(!plots)}
                  id="flexCheckChecked"
                />
                <label
                  className="form-check-label visitor-resident-value"
                  for="flexCheckChecked"
                >
                  Plots
                </label>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={villa}
                  onClick={() => setVilla(!villa)}
                  checked={villa == true}
                  id="flexCheckChecked"
                />
                <label
                  className="form-check-label visitor-resident-value"
                  for="flexCheckChecked"
                >
                  Villa
                </label>
              </div>
              <div className="form-check Commercial-tab">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={commercial == true}
                  onClick={() => setCommercial(!commercial)}
                  checked={commercial == true}
                  id="flexCheckChecked"
                />
                <label
                  className="form-check-label visitor-resident-value"
                  for="flexCheckChecked"
                >
                  Commercial
                </label>
              </div>
            </div>
            <div className="Commercial-phn gap-3 mt-2 mb-2 mb-lg-0">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={commercial == true}
                  onClick={() => setCommercial(!commercial)}
                  checked={commercial == true}
                  id="flexCheckChecked"
                />
                <label
                  className="form-check-label visitor-resident-value"
                  for="flexCheckChecked"
                >
                  Commercial
                </label>
              </div>
            </div>
          </div> */}
          <div className="mt-1 mb-0 mb-sm-1 pb-3 pb-sm-2 col-sm-3 align-self-sm-end">
            <button
              type="button"
              className="visitor-btn"
              onClick={() => handleValidateForm()}
            >
              Register as a Visitor
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
